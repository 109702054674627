import { Brand, ThemeProvider, ToastProvider } from '@newcross-ui/react';
import { PropsWithChildren } from 'react';
import { ModalProvider } from '../Modal';
import { ErrorBoundary } from '../ErrorBoundary';

export const AllProviders = ({ children }: PropsWithChildren) => (
  <ErrorBoundary>
    <ThemeProvider brand={Brand.healthforce}>
      <ToastProvider>
        <ModalProvider>{children}</ModalProvider>
      </ToastProvider>
    </ThemeProvider>
  </ErrorBoundary>
);
