import { QueryClient } from '@tanstack/react-query';
import { Permission } from '../types';

export const NUMBER_REGEX = /^\d+$/;
export const NUMBER_OR_EMPTY_REGGEX = /^(?:\+\d+|\d+)?$/;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
      gcTime: 0,
    },
  },
});

export const GENERIC_ERROR_MESSAGE = 'Something went wrong.';

export const PERMISSIONS = [
  'get-available-shifts-list',
  'get-assigned-shifts-list',
  'get-shifts-to-release-list',
  'get-shift-details',
  'assign-candidate-to-shift',
  'change-shift-candidate',
  'get-candidates-list',
  'delete-candidate',
  'create-candidate',
  'upload-candidates',
  'update-candidate',
  'get-vendors-list',
  'get-vendor',
  'create-vendor',
  'update-vendor',
  'create-web-push-token',
  'get-candidate-csv-template',
] as const;

export const REQUIRED_PERMISSIONS_TO_VISIT_AVAILABLE_SHIFTS: Permission[] = [
  'get-available-shifts-list',
];

export const REQUIRED_PERMISSIONS_TO_VISIT_ASSIGNED_SHIFTS: Permission[] = [
  'get-assigned-shifts-list',
];

export const REQUIRED_PERMISSIONS_TO_VISIT_REVIEW_SHIFTS: Permission[] = [
  'get-shifts-to-release-list',
];

export const REQUIRED_PERMISSIONS_TO_VISIT_MANAGE_PEOPLE: Permission[] = [
  'get-candidates-list',
];

export const REQUIRED_PERMISSIONS_TO_VISIT_BOOKING_DETAILS: Permission[] = [
  'get-shift-details',
];

export const REQUIRED_PERMISSIONS_TO_VISIT_ACCOUNT_DETAILS: Permission[] = [
  'get-vendor',
];

export const MANAGE_STAFF_CACHE_KEY = 'candidates';
export const REVIEW_BOOKINGS_CACHE_KEY = 'reviewShifts';
export const ASSIGNED_BOOKINGS_CACHE_KEY = 'assignedShifts';
export const AVAILABLE_BOOKINGS_CACHE_KEY = 'availableShifts';

export const AVAILABLE_BOOKINGS_REFETCH_INTERVAL = 1 * 60 * 1000;

export const DAYS_ABBREVIATIONS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
] as const;

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

export const MONTHS_ABBREVIATIONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;

export const GRADES = [
  'HCA',
  'RGN',
  'SUPPWORK',
  'HC',
  'HCA-MEDS',
  'HCA-SENIOR',
  'EN',
  'RNLD',
  'RNC',
  'RNMH',
  'ADMIN',
  'SUPPWORK-MEDS',
  'HCA-OFS',
  'SW-OFS',
  'PHLEBOTOMI',
  'SW-SENIOR',
  'SPEC-RGN',
  'CLEANER',
  'COOK',
  'KITCHEN',
] as const;

export const RATINGS = ['Bad', 'Okay', 'Good'];

export const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];

export const TOO_MANY_FILES_ERROR_MESSAGE = 'Too many files';

export const UPLOAD_EXTENSION_CSV_ERROR_MESSAGE =
  'File must be a CSV and the file name must end with .CSV';

export const UPLOAD_FILE_TOO_LARGE_ERROR_MESSAGE =
  'File too large. Please split the file into two or more smaller files and upload them one at a time.';

export const EULA_PDF_DOWNLOAD_ERROR_MESSAGE =
  'There was an error downloading the EULA .pdf file';

export const DELETE_CANDIDATE_SUCCESS_MESSAGE = 'Staff successfully deleted';

export const MODAL_EXPIRY_TIME_MINUTES = 5;
export const DEFAULT_NOTIFICATION_TITLE = 'Newcross Healthcare';
