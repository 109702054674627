/* eslint-disable */

import { DataRouteObject } from "react-router-dom";
import {
  FileRouteModule,
  makeAppPath,
  makePageComponentFromModule,
} from '@newcross-tech/react-conventional-routing-client';


import { withOneFullReloadOnError } from '@newcross-tech/react-conventional-routing-client';


export const APP_MANIFEST = {
  "root": {
    "id": "root",
    "file": "root.tsx",
    "path": ""
  },
  "routes/$": {
    "path": "*",
    "id": "routes/$",
    "parentId": "root",
    "file": "routes/$.tsx"
  },
  "routes/_app": {
    "id": "routes/_app",
    "parentId": "root",
    "file": "routes/_app.tsx"
  },
  "routes/_app._index": {
    "index": true,
    "id": "routes/_app._index",
    "parentId": "routes/_app",
    "file": "routes/_app._index.tsx"
  },
  "routes/_app.booking-details.$id": {
    "path": "booking-details/:id",
    "id": "routes/_app.booking-details.$id",
    "parentId": "routes/_app",
    "file": "routes/_app.booking-details.$id.tsx"
  },
  "routes/_app.faq": {
    "path": "faq",
    "id": "routes/_app.faq",
    "parentId": "routes/_app",
    "file": "routes/_app.faq.tsx"
  },
  "routes/_app.manage-staff": {
    "path": "manage-staff",
    "id": "routes/_app.manage-staff",
    "parentId": "routes/_app",
    "file": "routes/_app.manage-staff.tsx"
  },
  "routes/_app.my-account": {
    "path": "my-account",
    "id": "routes/_app.my-account",
    "parentId": "routes/_app",
    "file": "routes/_app.my-account.tsx"
  },
  "routes/_app.my-bookings": {
    "path": "my-bookings",
    "id": "routes/_app.my-bookings",
    "parentId": "routes/_app",
    "file": "routes/_app.my-bookings.tsx"
  },
  "routes/_app.not-found": {
    "path": "not-found",
    "id": "routes/_app.not-found",
    "parentId": "routes/_app",
    "file": "routes/_app.not-found.tsx"
  },
  "routes/_app.review-bookings": {
    "path": "review-bookings",
    "id": "routes/_app.review-bookings",
    "parentId": "routes/_app",
    "file": "routes/_app.review-bookings.tsx"
  }
} as const;

export const APP_ROUTES: DataRouteObject[] = [
    {
      id: 'root',
      path: '',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/root.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      children: [
    {
      id: 'routes/$',
      path: '*',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/$.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app',
      
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      children: [
    {
      id: 'routes/_app._index',
      
      index: true,
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app._index.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app.booking-details.$id',
      path: 'booking-details/:id',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.booking-details.$id.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app.faq',
      path: 'faq',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.faq.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app.manage-staff',
      path: 'manage-staff',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.manage-staff.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app.my-account',
      path: 'my-account',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.my-account.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app.my-bookings',
      path: 'my-bookings',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.my-bookings.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app.not-found',
      path: 'not-found',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.not-found.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
,

    {
      id: 'routes/_app.review-bookings',
      path: 'review-bookings',
      
      lazy: 
    async function () {
      const Module = (await withOneFullReloadOnError(() => import('../../../src/routes/_app.review-bookings.tsx'))) as FileRouteModule;

      return {
        Component: makePageComponentFromModule((Module)),
        ErrorBoundary: (Module).ErrorBoundary,
        loader: (Module).loader,
        action: (Module).action,
      }
    }
,
      
    }
],
    }
],
    }
];

export const APP_PATHS = 
{
  '/*': '/*',
'/': '/',
'/booking-details/:id': '/booking-details/:id',
'/faq': '/faq',
'/manage-staff': '/manage-staff',
'/my-account': '/my-account',
'/my-bookings': '/my-bookings',
'/not-found': '/not-found',
'/review-bookings': '/review-bookings'
} as const
;

export type AppPaths = typeof APP_PATHS;
export type AppPath = keyof AppPaths;

export const appPath = makeAppPath(APP_PATHS);