import { Button } from '@newcross-ui/react';
import { Link } from 'react-router-dom';
import { appPath } from '@/routing';

export const BackToHomepage = () => {
  return (
    <Link to={appPath('/')}>
      <Button data-testid='back-to-home'>Go to Available Bookings</Button>
    </Link>
  );
};
