import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { getKeycloak, initKeycloak } from '@newcross-tech/react-keycloak';
import { AllProviders } from '@/common/components/AllProviders';
import GlobalStyle from '@/index.style';
import { queryClient } from './common/constants';
import './assets/fonts.css';
import { StatusTemplate } from './common/components/StatusTemplate';
import { sentryService } from './common/services/sentryService';
import { ENVIRONMENT_VARIABLES } from './environmentVariables';

const { KEYCLOAK_REALM, KEYCLOAK_URL, KEYCLOAK_CLIENT_ID } =
  ENVIRONMENT_VARIABLES;

sentryService.init();

async function main() {
  const shouldRender = await initKeycloak(
    {
      realm: KEYCLOAK_REALM,
      url: KEYCLOAK_URL,
      clientId: KEYCLOAK_CLIENT_ID,
    },
    { onLoad: 'login-required' },
  );

  const token = getKeycloak()?.token;
  window.localStorage.setItem('keycloak_token', token ?? 'n/a');

  render(shouldRender);
}

const render = async (shouldRender: boolean) => {
  const { router } = await import('@/routing/browser-router');

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        {document.cookie.indexOf('devtools_ignore') === -1 && (
          <ReactQueryDevtools position={'bottom'} initialIsOpen={false} />
        )}
        <AllProviders>
          <GlobalStyle />
          {shouldRender ? (
            <RouterProvider router={router} />
          ) : (
            <StatusTemplate variant={'internal-error'} isGlobalError />
          )}
        </AllProviders>
      </QueryClientProvider>
    </React.StrictMode>,
  );
};

main();
