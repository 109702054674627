import { Theme } from '@newcross-ui/react';
import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  
  body {
    overscroll-behavior: none;
    font-family: 'Poppins', sans-serif;
    margin: 0;

    ${({ theme }: Theme) => css`
      color: ${theme.ColorPrimaryGravitas};
      background-color: ${theme.ColorBaseGrey500};
    `};
  }
`;

export default GlobalStyle;
