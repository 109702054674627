import {
  IconDefinition,
  faMagnifyingGlass,
  faPlugCircleExclamation,
} from '@fortawesome/pro-duotone-svg-icons';
import { SHIFT_DETAILS_ERROR_MESSAGE } from '@/features/shifts/constants';
import { ACCOUNT_DETAILS_NOT_FOUND_ERROR_MESSAGE } from '@/features/account/constants';
import { StatusVariant } from './StatusTemplate';

const somethingWentWrong = 'Ooops, something went wrong!';

export const statusContentConfig: Record<
  StatusVariant,
  {
    title: string;
    mainSubtitle: string;
  }
> = {
  'not-found': {
    title: somethingWentWrong,
    mainSubtitle:
      'Sorry, but the page you’re looking for may have been moved, deleted or never existed.',
  },
  'booking-not-found': {
    title: somethingWentWrong,
    mainSubtitle: SHIFT_DETAILS_ERROR_MESSAGE,
  },
  'internal-error': {
    title: 'Eeek! Internal Server error',
    mainSubtitle:
      'We’re fixing it. Please refresh the page or try again later.',
  },
  'account-details-not-found': {
    title: somethingWentWrong,
    mainSubtitle: ACCOUNT_DETAILS_NOT_FOUND_ERROR_MESSAGE,
  },
};

export const bannerContentMap: Record<
  StatusVariant,
  {
    title: string;
    icon: IconDefinition;
    subtitle: () => JSX.Element;
  }
> = {
  'not-found': {
    title: statusContentConfig['not-found'].title,
    icon: faMagnifyingGlass,
    subtitle: () => <>{statusContentConfig['not-found'].mainSubtitle}</>,
  },
  'booking-not-found': {
    title: statusContentConfig['booking-not-found'].title,
    icon: faMagnifyingGlass,
    subtitle: () => (
      <>{statusContentConfig['booking-not-found'].mainSubtitle}</>
    ),
  },
  'account-details-not-found': {
    title: statusContentConfig['account-details-not-found'].title,
    icon: faMagnifyingGlass,
    subtitle: () => (
      <>{statusContentConfig['account-details-not-found'].mainSubtitle}</>
    ),
  },
  'internal-error': {
    title: statusContentConfig['internal-error'].title,
    icon: faPlugCircleExclamation,
    subtitle: () => <>{statusContentConfig['internal-error'].mainSubtitle}</>,
  },
};
