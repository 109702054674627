import { ExtendedTheme, Theme, Typography } from '@newcross-ui/react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContainerType } from './Modal';

export const Container = styled.div<ContainerType>`
  ${({ theme, isOpen }: ExtendedTheme<ContainerType>) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;

    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'all' : 'none'};
    transition: all 0.3s ease;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: ${theme.ColorBaseBlack100};
    }
  `};
`;

export const Dialog = styled.dialog`
  ${({ theme }: Theme) => css`
    width: 500px;
    max-width: calc(100vw - ${theme.SpacingBase16} * 4);
    max-height: calc(100vh - ${theme.SpacingBase16} * 4);
    color: ${theme.ColorPrimaryGravitas};
    border: none;
    padding: ${theme.SpacingBase32};
    border-radius: ${theme.TextInputBorderRadius};
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${({ theme }: Theme) => css`
    gap: ${theme.SpacingBase16};
  `};
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  ${({ theme }: Theme) => css`
    color: ${theme.ColorPrimaryGravitas};
    width: ${theme.SpacingBase24};
    height: ${theme.SpacingBase24};
    cursor: pointer;
  `}
`;

export const SubTitle = styled(Typography)`
  ${({ theme }: Theme) => css`
    color: ${theme.ColorBaseGrey100};
    text-align: center;
  `}
`;

export const Body = styled.div`
  ${({ theme }: Theme) => css`
    max-height: 70vh;
    overflow-y: auto;
    margin-top: ${theme.SpacingBase16};
    font-family: ${theme.TypographyFontFamilyPoppinsRegular};
  `}
`;

export const Footer = styled.div`
  ${({ theme }: Theme) => css`
    margin-top: ${theme.SpacingBase24};
  `}
`;
