import styled, { css } from 'styled-components';
import {
  Typography,
  TypographyProps,
  ExtendedTheme,
  Theme,
} from '@newcross-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusTemplateProps } from './StatusTemplate';

const Text = styled(Typography)`
  ${({ theme }: ExtendedTheme<TypographyProps>) => css`
    color: ${theme.ColorBaseBlue100};
  `};
`;

export const BannerText = styled(Text)`
  ${({ theme }: ExtendedTheme<TypographyProps>) => css`
    margin-bottom: ${theme.SpacingBase4};
    font-size: ${theme.SpacingBase32};
    line-height: ${theme.TypographyLineHeight40};

    @media (max-width: ${theme.GridLaptopBreakpoint}px) {
      font-size: ${theme.SpacingBase32};
      line-height: ${theme.TypographyLineHeight40};
    }

    @media (max-width: ${theme.GridTabletBreakpoint}px) {
      font-size: 28px;
      line-height: ${theme.TypographyLineHeight32};
    }
  `};
`;

export const InfoText = styled(Text)`
  align-self: stretch;
  max-width: 75ch;
  ${({ theme }: Theme) => css`
    margin-bottom: ${theme.SpacingBase32};
  `};
`;

export const Container = styled('div')<
  ExtendedTheme<Pick<StatusTemplateProps, 'isGlobalError'>>
>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ isGlobalError }) => css`
    ${isGlobalError
      ? css`
          min-height: 100vh;
        `
      : css`
          height: 100%;
        `};
  `};
`;

//TODO change this to baby gravitas token

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Icon = styled(FontAwesomeIcon)<any>`
  ${({ theme }: Theme) => css`
    width: 10rem;
    height: 10rem;
    color: #5179a9;
    margin-bottom: ${theme.SpacingBase32};
  `};
`;

export const ContentArea = styled.div`
  text-align: center;

  > button,
  > a > button {
    width: 288px;
  }
`;
