import * as Sentry from '@sentry/react';
import { PropsWithChildren } from 'react';
import { StatusTemplate } from '../StatusTemplate';

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <Sentry.ErrorBoundary
      fallback={<StatusTemplate variant='internal-error' />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
