import { ActionType } from './actions';
import { ModalProps } from '../Modal';

export const reducer = (state: ModalProps, action: ActionType) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { isOpen: true, ...action.payload };
    case 'CLOSE_MODAL':
      return { ...state, isOpen: false };
  }
};
