export const ASSIGNED_SHIFTS_TABLE_HEADERS = [
  'Booking Id',
  'Client Name',
  'Post Code',
  'Start Date',
  'Duration',
  'Grade',
  'Assignee',
  'Status',
];

export const SINGLE_RELEASE_SUCCESS_MESSAGE = 'Booking released successfully';

export const SINGLE_RELEASE_BREAK_LENGTH_ERROR_MESSAGE =
  'Break length cannot be longer than booking duration';

export const SINGLE_RELEASE_ERROR_MESSAGES = {
  endDateValidation:
    'Actual End Date-time should be greater than actual Start Date-time',
  actualEndDate: { required: 'Actual End Date is required' },
  actualStartDate: { required: 'Actual Start Date is required' },
  actualEndTime: { required: 'Actual End Time is required' },
  actualStartTime: { required: 'Actual Start Time is required' },
  actualBreakLength: {
    required: 'Actual Break Length is required',
    pattern: 'Actual Break Length must be a valid number',
  },
};

export const MULTIPLE_RELEASE_SUCCESS_MESSAGE =
  'Bookings released to client successfully';

export const AVAILABLE_SHIFTS_TABLE_COLUMNS = [
  'Booking Id',
  'Client Name',
  'Post Code',
  'Start Date',
  'Duration',
  'Grade',
];

export const REVIEW_SHIFTS_TABLE_HEADERS = [
  '',
  'Booking Id',
  'Client Name',
  'Assignee',
  'Start Date',
  'End Date',
  'Break Length',
];

export const VIEW_SHIFT_CORE_DETAILS_MAPPER = {
  clientName: 'Client Name',
  clientAddress: 'Client Address',
  clientPostcode: 'Client Postcode',
  shiftStartDateTime: 'Start Date',
  shiftEndDateTime: 'End Date',
  shiftDuration: 'Shift Duration',
  grade: 'Grade',
  skills: 'Skills',
  environments: 'Environments',
  deathVerification: 'Death Verification',
  languages: 'Languages',
  loneWorker: 'Lone Worker',
  sleeperShift: 'Sleeper Shift',
  instructionsOnArrival: 'Instructions on Arrival',
  shiftId: 'Shift ID',
  breakLength: 'Break Length',
  gender: 'Required Gender',
} as const;

export const REVIEW_BOOKINGS_RELEASE_MODAL_MESSAGE =
  'You are about to send {{X}} booking(s) to the client for their approval. Do you wish to proceed?';

export const SHIFT_DETAILS_ERROR_MESSAGE =
  'The booking you’re looking for can’t be found. It may be already covered, cancelled, or is no longer available';

export const ASSIGN_CANDIDATE_ERROR_MESSAGE =
  'There was an error assigning your staff to the booking. Go back to the Available Bookings and if the booking is still available, please try again';

export const REPLACE_CANDIDATE_ERROR_MESSAGE =
  'There was an error replacing your staff for the booking. Go back to My Bookings and try again';

export const AVAILABLE_BOOKINGS_CACHE_KEY = 'availableShifts';

export const NO_AVAILABLE_RATING = 'No Available Rating';
