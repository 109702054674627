import { Dispatch, ReactNode, createContext, useReducer } from 'react';
import { ActionType } from './actions';
import { reducer } from './reducer';
import { Modal } from '../Modal';

type ModalProviderProps = {
  children?: ReactNode;
};

const initialState = { isOpen: false, title: 'Not Title Provided' };

export const ModalContext = createContext<{
  dispatch: Dispatch<ActionType>;
}>({
  // eslint-disable-next-line no-console
  dispatch: () => console.warn('no ModalContextProvider is used!'),
});

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, dispatch] = useReducer(reducer, initialState);

  return (
    <ModalContext.Provider value={{ dispatch }}>
      <Modal {...modal} />
      {children}
    </ModalContext.Provider>
  );
};
