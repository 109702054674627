import { useCallback, useContext } from 'react';
import { ModalProps } from '@/common/components/Modal';
import { ModalContext } from '@/common/components/Modal/ModalProvider/ModalProvider';

export const useModal = () => {
  const { dispatch } = useContext(ModalContext);

  const openModal = useCallback(
    (payload: ModalProps) => {
      dispatch({
        type: 'OPEN_MODAL',
        payload,
      });
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' });
  }, [dispatch]);

  return { openModal, closeModal };
};
