import { MouseEvent, ReactNode, useEffect } from 'react';
import { Typography } from '@newcross-ui/react';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { useModal } from '@/common/hooks/useModal';
import * as Styled from './Modal.style';

export type ModalProps = {
  title: string;
  subTitle?: string;
  body?: ReactNode;
  footer?: ReactNode;
  isOpen?: boolean;
  disableCloseOnClickOutside?: boolean;
  disableCloseOnEscape?: boolean;
};

export type ContainerType = Pick<ModalProps, 'isOpen'>;

export const Modal = ({
  title,
  subTitle,
  body,
  footer,
  isOpen,
  disableCloseOnClickOutside,
  disableCloseOnEscape,
}: ModalProps) => {
  const { closeModal } = useModal();

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleClickOutside = () => {
    if (!disableCloseOnClickOutside) {
      closeModal();
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };
    if (!disableCloseOnEscape) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (!disableCloseOnEscape) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [closeModal, disableCloseOnEscape]);

  return (
    <Styled.Container
      isOpen={isOpen}
      onClick={handleClickOutside}
      data-testid='modal-container'>
      <Styled.Dialog
        open
        onClick={stopPropagation}
        data-testid={`modal-dialog-${isOpen ? 'open' : 'close'}`}>
        <Styled.HeaderContainer>
          <Typography variant='heading3' data-testid='modal-title'>
            {title}
          </Typography>
          <Styled.CloseIcon icon={faXmark} onClick={closeModal} />
        </Styled.HeaderContainer>
        {subTitle && (
          <Styled.SubTitle variant='paragraph2' data-testid='modal-sub-title'>
            {subTitle}
          </Styled.SubTitle>
        )}
        {body && <Styled.Body data-testid='modal-body'>{body}</Styled.Body>}
        {footer && (
          <Styled.Footer data-testid='modal-footer'>{footer}</Styled.Footer>
        )}
      </Styled.Dialog>
    </Styled.Container>
  );
};
