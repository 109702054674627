export const ACCOUNT_VALIDATION_ERROR_MESSAGES = {
  contactName: {
    required: 'Contact name is required',
    trim: 'Contact name cannot start or end with a space',
    min: 'Contact name must be at least 3 characters',
    max: 'Contact name cannot be more than 100 characters',
    pattern: 'Contact name can only contain letters and hyphens',
  },
  email: {
    required: 'Email is required',
    pattern: 'Not a valid email',
  },
  phone: {
    required: 'Phone number is required',
    pattern: 'Phone number must be a valid phone number',
  },
  alternativePhone: {
    pattern: 'Alternative phone number must be a valid phone number',
  },
  name: { required: 'Name is required' },
};

export const UPDATE_ACCOUNT_DETAILS_ERROR_MESSAGE =
  'There was a problem updating your details. Please try again and if the problem persists, please contact support.';

export const UPDATE_ACCOUNT_DETAILS_SUCCESS_MESSAGE =
  'Your details have been successfully updated.';

export const ACCOUNT_DETAILS_NOT_FOUND_ERROR_MESSAGE =
  "Account details can't be found";
