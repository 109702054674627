import { ReactNode } from 'react';
import { Container } from '@newcross-ui/react';
import { bannerContentMap } from './StatusTemplate.constants';
import * as Styled from './StatusTemplate.style';
import { BackToHomepage } from '../BackToHomepage';

export type StatusVariant =
  | 'not-found'
  | 'internal-error'
  | 'booking-not-found'
  | 'account-details-not-found';

export type StatusTemplateProps = {
  variant: StatusVariant;
  children?: ReactNode;
  isGlobalError?: boolean;
};

export const StatusTemplate = ({
  variant,
  isGlobalError = false,
  children,
}: StatusTemplateProps) => {
  const {
    title,
    icon,
    subtitle: SubtitleComponent,
  } = bannerContentMap[variant];

  return (
    <Styled.Container data-testid={variant} isGlobalError={isGlobalError}>
      <Styled.Icon icon={icon} data-testid={`${variant}-icon`} />
      <Styled.ContentArea>
        <Styled.BannerText variant='heading1'>{title}</Styled.BannerText>
        <Styled.InfoText variant='paragraph1'>
          <SubtitleComponent />
        </Styled.InfoText>
        <Container gap='SpacingBase4' display='flex' flexDirection='column'>
          {children}
          {variant !== 'internal-error' && <BackToHomepage />}
        </Container>
      </Styled.ContentArea>
    </Styled.Container>
  );
};
